var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card-body bg-white p-b-5 p-relative pt10"},[(false)?_c('img',{staticClass:"share-icon",attrs:{"src":"/assets/img/icons/share-alt-light-black.svg","alt":""}}):_vm._e(),_c('FlightDesData',{staticClass:"m-t-10",attrs:{"flight":_vm.flight}},[_vm._t("flight-des-action-part",null,{"slot":"action-part"})],2),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"m-t-20 fs14 text-center"},[_vm._v(" "+_vm._s(_vm.$t( 'FLIGHTS.PRICE' ))+": "),_c('b',{staticClass:"fs16"},[_vm._v(_vm._s(_vm._f("formatPrice")(_vm.flight.price.price))+"₪")])]),_c('router-link',{attrs:{"to":{
          name: 'FlightDetails',
          params: {
            provider: _vm.flight.provider,
            booking_token: _vm.flight.booking_token,
            passengers: _vm.passengers_route_param,
            baggage: _vm.baggage_route_param,
          },
        }}},[_c('button',{staticClass:"primary-btn small-btn fs16 bg-green6"},[_vm._v(" "+_vm._s(_vm.$t( 'FLIGHTS.BOOK_FLIGHT' ))+" ")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }