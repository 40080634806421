<template>
  <div>
    <div class="card-body bg-white p-b-5 p-relative pt10">
      <img v-if="false" src="/assets/img/icons/share-alt-light-black.svg" alt="" class="share-icon">
      <FlightDesData class="m-t-10" :flight="flight">
        <slot name="flight-des-action-part" slot="action-part"></slot>
      </FlightDesData>
      <div class="d-flex flex-column align-items-center">
        <div class="m-t-20 fs14 text-center">
          {{ $t( 'FLIGHTS.PRICE' ) }}: <b class="fs16">{{ flight.price.price | formatPrice }}₪</b>
        </div>
        <router-link
          :to="{
            name: 'FlightDetails',
            params: {
              provider: flight.provider,
              booking_token: flight.booking_token,
              passengers: passengers_route_param,
              baggage: baggage_route_param,
            },
          }"
        >
          <button class="primary-btn small-btn fs16 bg-green6">
            {{ $t( 'FLIGHTS.BOOK_FLIGHT' ) }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CategoryShare from '../../CategoryShare';
import FlightDesData from '../FlightDesData';

export default {
  name: "FlightDesItem",
  props: {
    flight: {
      type: Object,
      default: null
    }
  },
  components: {
    CategoryShare,
    FlightDesData,
  },
  methods: {
  },
  computed: {
    ...mapState({
      // loading: (state) => state.flights.loading,
    }),
    ...mapGetters('flights', {
      passengers_route_param: 'passengers_route_param',
      baggage_route_param: 'baggage_route_param',
    }),
  }
}
</script>

<style lang="scss" scoped>
  .fs16 {
    font-size: 16px !important;
  }
  .share-icon {
    position: absolute;
    right: 12px;
    bottom: 12px;
    height: 20px;
  }
</style>