<template>
   <modal
    :width="1150"
    :adaptive="true"
    class="fullDetailModal CEModal modal-scroll-bar"
    :name="modalName"
    :ref="modalName"
    @closed="closeModalFunction"
  >
       <button class="button cus-close-modal_btn" @click="closeModal()">
         <img src="/assets/img/close.svg" alt />
       </button>

     <div v-if="flight">
       <div class="d-flex align-items-center flex-wrap m-t-20">
         <h4 class="m-b-10">{{ $t( "FLIGHTS.TRIP_SUMMARY" ) }}</h4>
         <TripSummary :flight="flight" class="mx20 m-b-10"></TripSummary>
       </div>
       <div class="row m-t-15">
         <div v-if="flight.to_routes && flight.to_routes.length" class="col-md-4">
           <FligntTicket :routes="flight.to_routes"></FligntTicket>
         </div>
         <div v-if="flight.return_routes && flight.return_routes.length" class="col-md-4">
           <FligntTicket :routes="flight.return_routes"></FligntTicket>
         </div>
         <div class="col-md-4">
           <BaggageInfo :baglimit="flight.baglimit" :bagsprice="flight.bagsprice"></BaggageInfo>
           <CovideNotify class="m-t-15"></CovideNotify>
         </div>
       </div>
       <p v-if="flight.return_routes && flight.return_routes.length && firstReturnRoute" class="fs14 my20">
         {{ flight.nights_in_dest }} {{ $t( "FLIGHTS.NIGHTS_IN" ) }} {{ flight.to.city_name }} <b>{{ $t('FLIGHTS.THEN_DEPART_FROM') }}</b> <b>{{ firstReturnRoute.from.airport_name || firstReturnRoute.from.city_name }} ({{ firstReturnRoute.from.airport_code }})</b>
       </p>
       <div class="row pt20">
         <div class="col-md-3 d-flex align-items-end">
           <router-link
             class="primary-btn primary-btn_sm bg-green6"
             :to="{
                name: 'FlightDetails',
                params: {
                  provider: flight.provider,
                  booking_token: flight.booking_token,
                  passengers: passengers_route_param,
                  baggage: baggage_route_param,
                },
              }"
           >
             <button>
               {{ $t( "FLIGHTS.BOOK_FLIGHT" ) }}
             </button>
           </router-link>
           <button class="primary-btn primary-btn_sm bg-grey8" @click="closeModal()">
             {{ $t( "COMMON.CLOSE" ) }}
           </button>
         </div>
       </div>
     </div>
  </modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import  FligntTicket from "../tradeShow/FligntTicket.vue";
import  BaggageInfo from "../tradeShow/BaggageInfo.vue";
import  CovideNotify from "../tradeShow/CovideNotify.vue";
import  TripSummary from "../tradeShow/TripSummary.vue";

export default {
  name: 'FullDetailModal',
  components: {
    FligntTicket,
    BaggageInfo,
    CovideNotify,
    TripSummary,
  },
  props: {
    flight: {
      type: Object,
      default: null
    },
    modalName: {
      type: String,
      default: 'modal'
    },
    closeModalFunction: {
      type: Function
    },
  },
  computed: {
    ...mapState({
      // loading: (state) => state.flights.loading,
    }),
    ...mapGetters('flights', {
      passengers_route_param: 'passengers_route_param',
      baggage_route_param: 'baggage_route_param',
    }),
    firstReturnRoute() {
      return this.flight.return_routes && this.flight.return_routes.length ? this.flight.return_routes[0] : null;
    },
  },
  methods: {
    closeModal() {
      this.closeModalFunction();
    },
  },
}
</script>

<style lang="scss">
  .fullDetailModal .vm--modal{
    padding: 20px;
  }
</style>