var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:_vm.modalName,staticClass:"fullDetailModal CEModal modal-scroll-bar",attrs:{"width":1150,"adaptive":true,"name":_vm.modalName},on:{"closed":_vm.closeModalFunction}},[_c('button',{staticClass:"button cus-close-modal_btn",on:{"click":function($event){return _vm.closeModal()}}},[_c('img',{attrs:{"src":"/assets/img/close.svg","alt":""}})]),(_vm.flight)?_c('div',[_c('div',{staticClass:"d-flex align-items-center flex-wrap m-t-20"},[_c('h4',{staticClass:"m-b-10"},[_vm._v(_vm._s(_vm.$t( "FLIGHTS.TRIP_SUMMARY" )))]),_c('TripSummary',{staticClass:"mx20 m-b-10",attrs:{"flight":_vm.flight}})],1),_c('div',{staticClass:"row m-t-15"},[(_vm.flight.to_routes && _vm.flight.to_routes.length)?_c('div',{staticClass:"col-md-4"},[_c('FligntTicket',{attrs:{"routes":_vm.flight.to_routes}})],1):_vm._e(),(_vm.flight.return_routes && _vm.flight.return_routes.length)?_c('div',{staticClass:"col-md-4"},[_c('FligntTicket',{attrs:{"routes":_vm.flight.return_routes}})],1):_vm._e(),_c('div',{staticClass:"col-md-4"},[_c('BaggageInfo',{attrs:{"baglimit":_vm.flight.baglimit,"bagsprice":_vm.flight.bagsprice}}),_c('CovideNotify',{staticClass:"m-t-15"})],1)]),(_vm.flight.return_routes && _vm.flight.return_routes.length && _vm.firstReturnRoute)?_c('p',{staticClass:"fs14 my20"},[_vm._v(" "+_vm._s(_vm.flight.nights_in_dest)+" "+_vm._s(_vm.$t( "FLIGHTS.NIGHTS_IN" ))+" "+_vm._s(_vm.flight.to.city_name)+" "),_c('b',[_vm._v(_vm._s(_vm.$t('FLIGHTS.THEN_DEPART_FROM')))]),_vm._v(" "),_c('b',[_vm._v(_vm._s(_vm.firstReturnRoute.from.airport_name || _vm.firstReturnRoute.from.city_name)+" ("+_vm._s(_vm.firstReturnRoute.from.airport_code)+")")])]):_vm._e(),_c('div',{staticClass:"row pt20"},[_c('div',{staticClass:"col-md-3 d-flex align-items-end"},[_c('router-link',{staticClass:"primary-btn primary-btn_sm bg-green6",attrs:{"to":{
              name: 'FlightDetails',
              params: {
                provider: _vm.flight.provider,
                booking_token: _vm.flight.booking_token,
                passengers: _vm.passengers_route_param,
                baggage: _vm.baggage_route_param,
              },
            }}},[_c('button',[_vm._v(" "+_vm._s(_vm.$t( "FLIGHTS.BOOK_FLIGHT" ))+" ")])]),_c('button',{staticClass:"primary-btn primary-btn_sm bg-grey8",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t( "COMMON.CLOSE" ))+" ")])],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }