<template>
  <div class="tourism-content">
    <section v-if="false" class="tourism-product__gallery">
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-1">
          <img class="tourism-product__image" src="/assets/img/pool-hotel.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-2">
          <img class="tourism-product__image" src="/assets/img/hotel.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-3">
          <img class="tourism-product__image" src="/assets/img/hall.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-4">
          <img class="tourism-product__image" src="/assets/img/terrace.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-5">
          <img class="tourism-product__image" src="/assets/img/pool-croped.png" alt="">
      </div>

      <a class="link tourism-product__more-photo" href=""></a>
    </section>
    <section class="tourism-container">
      <div v-if="false" class="d-flex align-items-center justify-content-between flex-wrap m-t-20">
        <div class="d-flex align-items-center">
          <h4>
            {{ $t( "FLIGHTS.FLIGHTS" ) }} - {{ fromDestinationName }}
          </h4>
          <img src="/assets/img/icons/flight.svg" alt="" class="mx10">
          <h4>
            {{ toDestinationsNames }}
          </h4>
        </div>
        <div class="d-flex fs14">
          <p class="mx10">
            {{ $t( "FLIGHTS.DEPARTURE_DATE" ) }}: <b>01/04/2021</b>
          </p>
          <p class="mx10">
            {{ $t( "FLIGHTS.RETURN_DATE" ) }}: <b>10/04/2021</b>
          </p>
          <p class="mx10">
            {{ $t( "FLIGHTS.DURATION" ) }}: <b>3 - 5 Nights</b>
          </p>
          <p class="mx10">
            {{ $t( "FLIGHTS.TRAVELERS" ) }}: <b>2 Adults</b>
          </p>
        </div>
        <div v-if="false" class="d-flex">
          <!--<SortTourism @sortDeals="sortDeals" :isTourismDeal="true"></SortTourism>-->
          <!--<FilterTourism></FilterTourism>-->
        </div>
      </div>
      <div class="row m-t-5">
        <div v-for="(flight, index) in flights"
             class="col-md-3"
             :key="index + '_' + flight.id"
        >
          <FlightDesItem :flight="flight">
            <button
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15"
              slot="flight-des-action-part"
              @click="openFullDetailModal(flight)"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "FLIGHTS.FULL_DETAILS" ) }}
            </button>
          </FlightDesItem>
        </div>
      </div>
    </section>

    <FullDetailModal
      :flight="fullDetailFlightData"
      :modalName="fullDetailModalName"
      :closeModalFunction="closeFullDetailModal"
    ></FullDetailModal>

    <loading :active.sync="search_in_process" :can-cancel="false" :is-full-page="true"></loading>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FlightDesItem from "../../../components/tourism/flight/FlightDesItem";
import FullDetailModal from "../../../components/tourism/flight/FullDetailModal.vue";

export default {
  name: "DestinationsFlights",
  components: {
    FlightDesItem,
    FullDetailModal
  },
  data() {
    return {
      fullDetailModalName: 'full-detail-modal',
      fullDetailFlightData: null
    }
  },
  computed: {
    ...mapState({
      search_in_process: (state) => state.flights.search_in_process,
      searched: (state) => state.flights.searched,
    }),
    ...mapGetters('flights', {
      filter_in_use: 'filter_in_use',
      destinations_flights: 'destinations_flights',
      location_option: 'location_option',
    }),
    flights () {
      return this.destinations_flights(this.$route.params.destination_codes) || [];
    },
    fromDestinationName() {
      let fromFilter = this.filter_in_use('from');
      let fromOption = this.location_option(fromFilter);

      let fromDestinationName = fromOption ? fromOption.name : '-';
      return fromDestinationName;
    },
    toDestinationsNames() {
      let toFilter = this.filter_in_use('to');
      let toCodes = toFilter.split(',');
      let toDestinationsNames = [];
      for (let i = 0; i < toCodes.length; i++) {
        let toOption = this.location_option(toCodes[i]);
        toDestinationsNames.push(toOption ? toOption.name + ', ' + toOption.country_name: '-');
      }

      return toDestinationsNames.join('; ');
    }
  },
  methods: {
    openFullDetailModal(flight) {
      this.fullDetailFlightData = flight;
      this.$modal.show(this.fullDetailModalName);

    },
    closeFullDetailModal() {
      this.$modal.hide(this.fullDetailModalName);
      this.fullDetailFlightData = null;
    },
    sortDeals() {
    },
  },
  async beforeMount() {
    if (!this.$route.params.destination_codes) {
      await this.$router.push({ name: 'FlightHome', query: {
          ...this.$route.query
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";
</style>